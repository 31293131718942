import Chart from "chart.js";
import { num, dollars } from "./format";

const FONT_COLOR = "#748a9b";
const FONT_FAMILY = "Sourcesans, helvetica, sans-serif";
const FONT_SIZE = 13;
const BACKGROUND_COLOR = "#4f9ffb";
const LINE_COLOR = "#4f9ffb";
const VERTICAL_LINE_COLOR = "#cfcfcf";

function timestampToLabels(timestamps) {
  return timestamps.map((timestamp) => {
    const [year, month, day] = timestamp.split("-");
    return `${month.replace(/^0/, "")}/${day.replace(/^0/, "")}`;
  })
}

function initChart() {
  if (initChart.initialized) {
    return;
  } else {
    initChart.initialized = true;
  }

  Chart.defaults.global.defaultFontColor = FONT_COLOR;
  Chart.defaults.global.defaultFontFamily = FONT_FAMILY;
  Chart.defaults.global.elements.point.backgroundColor = BACKGROUND_COLOR;
  Chart.defaults.global.defaultFontSize = FONT_SIZE;
  Chart.defaults.global.animation.duration = 0;
  Chart.defaults.global.hover.animationDuration = 0;
  Chart.defaults.global.elements.line.tension = 0;

  Chart.plugins.register({
    // draw vertical line on hover
    afterDatasetsDraw: function(chart) {
        if (chart.tooltip._active && chart.tooltip._active.length) {
           const activePoint = chart.tooltip._active[0],
               ctx = chart.ctx,
               yAxis = chart.scales["y-axis-0"],
               x = activePoint.tooltipPosition().x,
               topY = yAxis.top,
               bottomY = yAxis.bottom;
           ctx.save();
           ctx.beginPath();
           ctx.moveTo(x, topY);
           ctx.lineTo(x, bottomY);
           ctx.lineWidth = 1;
           ctx.strokeStyle = VERTICAL_LINE_COLOR;
           ctx.stroke();
           ctx.restore();
        }
     }
  });
}

function createChart(key, labels, data, format = "num") {
  initChart();
  const chartEl = document.getElementById(`chart-${key}`);
  if (!chartEl) { return; }

  const dataset = {
    data,
    label: "",
    borderColor: LINE_COLOR,
    fill: false,
    pointRadius: 0,
    pointHitRadius: 0,
    lineTension: 0
  };
  return new Chart(chartEl.getContext("2d"), {
    type: 'line',
    data: { labels: timestampToLabels(labels), datasets: [dataset] },
    options: {
      responsive: false,
      maintainAspectRatio: true,
      legend: { display: false, },
      title: { display: false },
      tooltips: {
        intersect: false,
        backgroundColor: "#000",
        custom: function(tooltip) {
          if (!tooltip) { return; }
          tooltip.displayColors = false;
        },
        callbacks: {
          title: function(tooltipItems, data) {
            return data["labels"][tooltipItems[0]["index"]];
          },
          label: function(tooltipItem, data) {
            const value = data["datasets"][0]["data"][tooltipItem["index"]];
            return format === "dollars" ? dollars(value) : num(value);
          }
        }
      },
      scales: {
        yAxes: [{
          gridLines: { display: false },
          scaleLabel: { display: false },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 8,
            maxRotation: 0,
            minRotation: 0,
            precision: 0,
            callback: function(value, index, values) {
              if (value == 0) {
                return format === "dollars" ? "$0" : "0";
              } else {
                return format === "dollars" ? dollars(value) : num(value);
              }
            }
          }
        }],
        xAxes: [{
          gridLines: {
            display: false
          },
          scaleLabel: {
            display: false
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 7,
            maxRotation: 0,
            minRotation: 0
          }
        }]
      }
    }
  });
}

function ChartComponent(props) {
  return (
    <div class="chart">
      <h3>{props.title}</h3>
      <div class="chart-value">{props.format === "dollars" ? dollars(props.value) : num(props.value)}</div>
      <canvas id={`chart-${props.key}`} style="width:337px;height:168px"></canvas>
    </div>
  );
}

export {
  createChart,
  ChartComponent as Chart
};
